import React from 'react'

// Elements
import { Layout } from '@layouts'
import { Artical } from '@sections'
import { Par } from '@theme'
import { ContactCard, CardWrapper, Form, MapContainer } from '@components'

const Contact = (props) => (
	<Layout props={props}>
		<Artical
			title="Contacts"
			par={`E-mail, call or contact us via the enquiry form below. Office hours are 08h00 to 16h30 Monday to Thursday and 08h00 to 15h00 on Fridays.`}
		>
			<CardWrapper colRowCount={2} marginTop="2em">
				<div>
					<ContactCard
						label="Email"
						info={<Par>sales@pumptec.co.za</Par>}
						icon="email"
						marginBot="1em"
						email="sales@pumptec.co.za"
					/>
					<ContactCard
						label="Telephone"
						info={<Par>+27 (0) 11 822 1621</Par>}
						icon="tel"
						marginBot="1em"
						tel="+27118221621"
					/>
					<ContactCard
						label="Postal Address"
						info={
							<>
								<Par>PO Box 1415,</Par>
								<Par>Edenvale 1610</Par>
							</>
						}
						icon="postal"
						marginBot="2em"
					/>
				</div>
				<div>
					<ContactCard
						label="Physical Address"
						gridColumn={'2/3'}
						gridRow={'2/4'}
						info={
							<>
								<Par>419 Martin Crescent,</Par>
								<Par>Greenhills Industrial Estate,</Par>
								<Par>Tunney Ext 6,</Par>
								<Par>Johannesburg,</Par>
								<Par>South Africa, 1400</Par>
							</>
						}
						icon="building"
					/>
				</div>
			</CardWrapper>
		</Artical>

		<Artical
			theme="primary"
			title="Get in touch"
			subTitle="CONTACT FORM"
			par={`To find out more on our products and services provided, Fill in the contact form and we will get back to you.`}
		>
			<Form formId="contact_form" formSubject="Website contact form enquiry" />
		</Artical>

		<MapContainer>
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d895.267997203827!2d28.18515452317117!3d-26.161784869561153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1429e6663ad32e7%3A0x5297b116a49df1bd!2sPumptec%20Pty%20Ltd!5e0!3m2!1sen!2sza!4v1591731513342!5m2!1sen!2sza"
				width="100%"
				height="501px"
				frameBorder="0"
				allowFullScreen={false}
				aria-hidden={false}
				tabIndex={0}
			></iframe>
		</MapContainer>
	</Layout>
)

export default Contact
